$phoneLandscape: 30em; // 480px
$tablet: 48em; // 768px
$tabletLandscape: 64em; // 1024px
$desktop: 78.75em; // 1260px

$theme-colors: (
        "primary": #28a745
);

$body-bg: #343a40;
$body-color: #fff;

$primaryBackground: $body-bg;
$secondaryBackground: #41474d;

$blue: rgba(45, 100, 181, 1);
$transBlue: rgba(45, 100, 181, 0.4);
$red: rgba(153, 50, 8, 1);
$transRed: rgba(193, 50, 8, 0.4);

$borderColor: grey;

$btnActiveInnerShadow: inset 0px 0px 6px 0px white;

@import '~normalize.css';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
// Bootstrap base
@import '~bootstrap/scss/bootstrap';

@import './styles/App';
