.page {
    position: fixed;
    overflow: auto;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
}

.content-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow:hidden;

    .calendar {
        height: 2.4rem;

        .btn-left, .btn-right {
            margin: 0.2rem;
            width: 50px;
            background: $secondaryBackground;
            border:2px solid grey;
            position:absolute;
            top:0px;
            z-index:1;
        }

        .btn-left {
            left:10px;
        }

        .btn-right {
        right: 10px;
        }

        .datePickerContainer {
            padding: 16px 16px;
            background: #343a40;
            color: #fff;
        }

        .react-datepicker-wrapper {
            margin: 5px 40px 0 40px;
            width: 100%;
            display: inline-block;

            .react-datepicker__input-container{
                left: -40px;
            }

            input {
                display: block;
                margin: 0 auto;
                border: 0;
                background-color: inherit;
                color: white;
                text-align: center;
            }

            input:focus {
                border: 0;
                outline: none;
            }
        }

        .react-datepicker-popper {
            z-index: 1499;
            width: 100%;
            top: 5px !important;
            left: -55px !important;
            //right: 0 !important;
            text-align: center !important;
            //transform: none !important;


            .react-datepicker__day--highlighted-bike {
                border-radius: 0.3rem;
                background: #358e69;
                color: #fff;
            }

            .react-datepicker__day--highlighted-note {
                border-radius: 0.3rem;
                background: #790d0c;
                color: #fff;
            }

            .react-datepicker__day--highlighted-bike {
                &.react-datepicker__day--highlighted-note{
                    border-radius: 0.3rem;
                    background: linear-gradient(135deg, #358e69 0%,#358e69 50%,#790d0c 50%,#790d0c 100%);
                    color: #fff;
                }
            }

            .react-datepicker__day--today {
                border-radius: 0.3rem;
                background: #216ba5 !important;
                color: #fff;
            }

            .react-datepicker__day--selected {
                background-color: rgba(30, 83, 120, 0.24);
                color: #000;
                font-weight:bold;
            }
        }

        .react-datepicker-popper[data-placement^="bottom"] {
            margin-top: 0;
        }
    }

    .detail {
        height: calc(100% - 2.4rem);
        position: absolute;
        top: 2.4rem;
        right: 0;
        left: 0;

        .leaflet-container {
            overflow: hidden;
            height: 100%;
            outline: none;
        }

        &.show-detail {
            height: 300px;
        }
    }

    .trackermap {
        height: calc(100% - 2.4rem);
        position: absolute;
        top: 2.4rem;
        right: 0;
        left: 0;
        transition: all 0.5s ease-out;

        &.edit-active{
            border: 5px solid red;
        }

        .leaflet-container {
            overflow: hidden;
            height: 100%;
            outline: none;
        }

        &.show-detail {
            height: calc(100% - 2.4rem - 300px);
            top: calc(2.4rem + 300px);
            transition: all 0.5s ease-out;
        }
    }


}

.modal-content {
    background-color: #343a40;
}

.detail-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 505;


    .btn-round {
        width: 36px;
        height: 36px;
        padding: 0;
        box-shadow: 0 1px 5px rgba(0,0,0,0.4);
        background: #fff;
        border-radius: 5px;
        color:black;
    }

}



.loader-wrapper {
    position: absolute;
    bottom: 3px;
    right: 0;
    width: 100%;
    height: 10px;
    z-index: 99999;

    .loader {
        width: 250px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: red;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);

        &::before {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background: darkred;
            position: absolute;
            animation: load .9s infinite alternate ease-in-out;
        }

    }
}


@keyframes load {
    0% {
        left: 0;
        height: 10px;
        width: 15px;
    }
    50% {
        height: 10px;
        width: 80px;
        background: red;
    }
    100% {
        left: 235px;
        height: 10px;
        width: 15px;
    }
}

.mobile-navigation {
    padding: 15px 25px 15px 25px;

    .btn {
        margin: 5px;
    }

    @media (min-width: $tablet) {
        display: none;
    }
}


.panels {
    height: 220px;
    overflow: hidden;
    margin-right: 0px;
    margin-left: 0px;

    #keywords, #search, #trackpoints {
        width: 100%;
        float: left;
        overflow: auto;
        height: 100%;
        padding: 15px 15px 15px 15px;
    }

    #keywords {
        .keyword-textarea {
            height: 150px;
        }

        .save {
            padding-right: 0px;
        }

        .cancel {
            padding-left: 0px;
        }
    }

    #search {
        .result-box {
            margin-top: 10px;

            .result-row {
            }
        }
    }

    #trackpoints {
        .trackpoint-list {
            font-size: 0.6rem;
            margin: 5px;
            width: calc(100% - 5px);

            tbody {
                tr {
                    td {
                        width: 33.3%;
                    }

                    &.currentEditing {
                        border: 1px solid orange;
                    }
                }

                tr:nth-child(odd) {
                    background: $secondaryBackground;
                }
            }

        }
        .trackpoint-anchor {
            a {
                color: grey;
            }
        }
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }


    @media (min-width: $tablet) {
        height: 300px;

        #keywords, #search, #trackpoints {
            width: 33%;
        }

        #keywords {
            .keyword-textarea {
                height: 200px;
            }
        }

        .show {
            display: block;
        }
        .hide {
            display: block;
        }
    }
}

.leaflet-map {
    .leaflet-div-icon {
        background: transparent !important;
        border: none !important;
    }
    &.hide-controls{
        .leaflet-control-container {
            display:none;
        }
    }

}

.marker {
    position: absolute;
    bottom: 6px;
    left: -6px;
    text-shadow: 4px 3px 0px #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    &.gpx-marker{
        color: #790d0c;
    }
    &.bike-marker {
        color: #0051a1;
    }
    &.edit {
        text-shadow: 2px 0px 4px #fff;
        &.gpx-marker{
            color: #C7452B;
        }
        &.bike-marker {
            color: #2ca1ff;
        }
    }
}


.overlay {
    background: $primaryBackground;
    box-shadow: 3px 3px 5px #000;
    border-radius: 10px;
    position: absolute;
    z-index: 999;
    top: 10px;
    left: 10px;
    width: 150px;
    height: 200px;
    padding: 5px;
    .content{
        margin: 5px;
        div{
            width:100%;
            margin: 3px 0;
        }
        div:nth-child(odd){
            background:linear-gradient(135deg, #790d0c, $primaryBackground);
        }
        //button{
        //    margin-top: 10px;
        //    width: 50%;
        //}
        .btn-edit-close{
            position: absolute;
            top: -10px;
            right: -10px;
            height: 36px;
            width: 36px;
            border-radius: 18px;
            border: 3px solid $primaryBackground;
            background-color: #790d0c;
        }
    }
}

.version-info {
    position:absolute;
    bottom:30px;
    left:5px;
    width:100px;
    height:2rem;
    z-index:500;
    font-size:0.7rem;
}



